.elconc-skeleton {
	border-radius: 3px;
	background: linear-gradient(-45deg, #e7e7e7, #dddddd, #c9c7c7, #c9c7c7);
	background-size: 400% 400%;
	animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1.elconc-skeleton::before,
h2.elconc-skeleton::before,
h3.elconc-skeleton::before,
h4.elconc-skeleton::before,
h5.elconc-skeleton::before,
h6.elconc-skeleton::before,
p.elconc-skeleton::before,
span.elconc-skeleton::before,
time.elconc-skeleton::before,
a.elconc-skeleton::before {
	/* To emulate height of text. */
	content: ".";
	display: inline-block;
	color: transparent;
}

.skeleton {
	height: 48px;
}
