button {
	cursor: pointer;
	color: inherit;
	padding: 0;
	border: 0;
}

button.primary,
a.primary,
button.danger,
button.outline,
a.outline,
button.text,
a.text {
	font-size: var(--font-size-md);
	text-decoration: none;
	border: none;
	transition:
		background-color,
		opacity 300ms ease-in-out;
}

button.primary,
a.primary,
button.danger,
button.outline,
a.outline {
	padding: 10px 25px;
	border-radius: 100px;
}

button.primary,
a.primary {
	background-color: var(--primary-color);
	color: var(--primary-contrast-color);
}

button.outline,
a.outline {
	background-color: transparent;
	color: var(--primary-color);
	border: 1px solid var(--border-color);
}

button.text,
a.text {
	background-color: transparent;
	color: var(--primary-color);
}

button.danger {
	background-color: var(--error-color);
	color: var(--primary-contrast-color);
}

button.primary:active,
a.primary:active,
button.danger:active,
button.outline:active,
a.outline:active,
button.text:active,
a.text:active {
	transform: scale(0.99);
}

button.primary:disabled,
a.primary:disabled,
button.danger:disabled,
button.outline:disabled,
a.outline:disabled {
	background-color: var(--disabled-button-color);
}

button.primary:disabled,
a.primary:disabled,
button.danger:disabled,
button.outline:disabled,
a.outline:disabled,
button.text:disabled,
a.text:disabled {
	color: var(--disabled-text-color);
}
