:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

	--primary-color: #00399a;
	--primary-contrast-color: #ffffff;

	--secondary-color: #245bd5;
	--secondary-contrast-color: #ffffff;

	--tertiary-color: #cbe1ff;

	--error-color: #cf2b27;
	--warning-color: #fbda04;
	--success-color: #0bb70b;

	--background-color: #f3f3fd;
	--background-contrast-color: #ffffff;

	--primary-text-color: #000000;
	--secondary-text-color: var(--primary-color);

	--placeholder-color: #e7e7f2;
	--disabled-button-color: #d8d8e2;
	--disabled-text-color: #939393;

	--border-color: #c3c6d6;
	--border-contrast-color: var(--primary-color);
	--border-radius: 8px;

	--spacing-lg: 24px;
	--spacing-md: 16px;
	--spacing-sm: 8px;

	/** Typography */
	--title-font-size-lg: 28px;
	--title-font-size-md: 24px;
	--title-font-size-sm: 22px;

	--font-size-lg: 16px;
	--font-size-md: 14px;
	--font-size-sm: 12px;

	--space-tiny: 4px;
	--space-small: 8px;
	--space-medium: 16px;
	--space-large: 24px;
	--space-huge: 32px;
	--space-gigantic: 40px;
}
