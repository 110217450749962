.title {
	font-weight: 500;
}

.title_lg {
	font-size: var(--title-font-size-lg);
}

.title_md {
	font-size: var(--title-font-size-md);
}

.title_sm {
	font-size: var(--title-font-size-sm);
}

.text {
	font-weight: 400;
}

.text_lg {
	font-size: var(--font-size-lg);
}

.text_md {
	font-size: var(--font-size-md);
}

.text_sm {
	font-size: var(--font-size-sm);
}

.text_bold {
	font-weight: 500;
}
