.visually-hidden {
	/*
    To preserve a11y, hide radio button only visually
    https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	margin: 0;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.flex {
	display: flex;
}

.flex_column-spacing-sm,
.flex_column-spacing-md,
.flex_column-spacing-lg {
	flex-direction: column;
}

.flex_spacing-sm,
.flex_column-spacing-sm {
	gap: var(--spacing-sm);
}

.flex_spacing-md,
.flex_column-spacing-md {
	gap: var(--spacing-md);
}

.flex_spacing-lg,
.flex_column-spacing-lg {
	gap: var(--spacing-lg);
}
