.elcon-input {
	--input-sides-padding: 10px;
	--input-prefix-suffix-padding: calc(var(--input-sides-padding) + 35px);

	--input-border-color: hsla(0, 0%, 0%, 0.2);
	--input-disabled-border-color: hsla(0, 0%, 0%, 0.12);
	--input-disabled-background-color: hsla(0, 0%, 93%, 1);

	width: 100%;
	padding: var(--input-sides-padding) 15px;
	font-size: var(--font-size-400);
	line-height: inherit;
	color: inherit;
	border: 1px solid var(--input-border-color);
	border-radius: var(--border-radius);
	background-color: var(--background-color);
}

.elcon-input:focus {
	outline: none;
	border: 1px solid var(--primary-color);
}

.elcon-input:disabled {
	border: 1px solid var(--input-disabled-border-color);
	background-color: var(--input-disabled-background-color);
}

.elcon-input.has-prefix {
	padding-left: var(--input-prefix-suffix-padding);
}

.elcon-input.has-suffix {
	padding-right: var(--input-prefix-suffix-padding);
}

.elcon-input[type="search"] {
	padding: 10px;
	min-height: 56px;
	background-image: url(/assets/icons/search-icon.svg);
	background-size: 1.3rem;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: calc(20px + 1rem);
	font-size: var(--font-size-400);
	min-width: 250px;
}
