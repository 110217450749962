a {
	text-decoration: none;
	color: inherit;
}

.border-text {
	min-height: 32px;
	min-width: 42px;
	border: 1px solid var(--border-color);
	padding: 6px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius);
}
